import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/(app)/accounts": [7,[2]],
		"/(app)/accounts/[id]": [8,[2]],
		"/(app)/admin": [9,[2]],
		"/(app)/apps": [10,[2]],
		"/(app)/apps/maps": [11,[2]],
		"/(app)/apps/sitecards": [12,[2]],
		"/(app)/brands": [13,[2]],
		"/(app)/brands/[id]": [14,[2]],
		"/(app)/create-account": [15,[2]],
		"/(login)/login": [22,[3]],
		"/(app)/maps": [16,[2]],
		"/(app)/panels": [17,[2]],
		"/(app)/prop-builder": [18,[2]],
		"/(app)/quick-avails": [19,[2]],
		"/(app)/sales-tools": [20,[2]],
		"/(share)/share": [23,[4]],
		"/(share)/share/[slug]": [24,[4]],
		"/(app)/sitecards": [21,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';